import React from 'react'
import Layout from "../../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

function SEO() {

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "SEO - Bart Sallé, freelance webdeveloper";
  const description = "Wil je beter gevonden worden op Google? Ik maak heldere SEO analyses waardoor precies naar boven komt waar de verbeterpunten liggen.";
  const url = "https://www.bartsalle.nl/diensten/hoger-in-google/";

  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="overzichtdiensten" />
      </Helmet>
        <div className="container">
          <h1>Hoger in Google</h1>
          <p>Wil je beter gevonden worden op Google? Ik maak heldere SEO analyses waardoor precies naar boven komt waar de verbeterpunten liggen. In die analyses komen zowel on page SEO als off page SEO aan bod. Wat houdt dit precies in?</p>
          <StaticImage src="../../../images/seo.png" alt="webdesign voorbeeld" layout="constrained" width={500} className="float-right webdesign" placeholder="none" />
          <h2>On page SEO: website optimalisatie en verbeterde content</h2>
          <p>On page SEO is een verzamelnaam voor alle activiteiten die je op je websitepagina's uitvoert om beter vindbaar te worden. De eerste stap is een website optimalisatie test waarbij gekeken wordt naar de technische kant van de website. Is de site goed gebouwd, klopt de semantiek en hoe staat het met de snelheid van de website? In mijn analyse komen alle verbeterpunten direct naar boven en wordt helder uitgelegd wat er aangepast moet worden. Uiteraard kan ik deze aanpassingen ook voor je maken!</p>
          <p>Nadat we samen hebben afgestemd waar je graag op gevonden wilt worden voer ik een zoekwoorden onderzoek (keyword research) uit. Dit resulteert in een voorstel van een aantal zoekwoorden die het beste gebruikt kunnen worden om een hoge ranking te krijgen. Verbeter hiermee je website teksten om zo beter gevonden te worden in Google.</p>
          <p>Geen zin om zelf teksten te schrijven? Ik breng je graag in contact met een bevriende tekstschrijfster die gespecialiseerd is in het schrijven van goede SEO teksten.</p>
          <h2>Off page SEO: concurrentie & backlink analyse</h2>
          <p>Off page SEO bestaat voornamelijk uit het verzamelen van externe links naar de website om de website autoriteit te verbeteren. Dit verzamelen van links wordt ook wel linkbuilding genoemd.</p>
          <p>Op basis van de beste zoekwoorden uit mijn zoekwoorden onderzoek maak ik een concurrentie analyse zodat we kunnen zien welke websites hoog scoren op deze zoekwoorden.</p>
          <p>Van de belangrijkste concurrenten maak ik vervolgens een backlink analyse die resulteert in een aantal nuttige tips om zelf meer high quality backlinks te kunnen krijgen. Hoe meer (high quality) links er naar jouw site wijzen hoe belangrijker jij bent in de ogen van Google!</p>
          <h2>Snel je website bovenaan in Google krijgen?</h2>
          <p>Ik pleit altijd voor SEO maar soms is er geen tijd en wil een klant direct resultaat. Dan zullen we over moeten schakelen naar SEA (Search Engine Advertising) en kan ik één of meerdere Google Adwords advertenties voor je maken en beheren.</p>
          <h2>Wil je ook jouw website hoger in Google?</h2>
          <p><Link to="/contact/">Neem geheel vrijblijvend contact op,</Link> ik help je graag!</p>
        </div>
    </Layout>
  )
}

export default SEO